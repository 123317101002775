#back {
    height: 100vh;
    background-image: url("../../assets/bg.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-color: #020307;
    background-size: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.login-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 12px;
    padding: 10px 10px 10px 10px;
    margin-top: 45vh;
}

input[type=text],
input[type=password] {
    background-color: rgb(3, 4, 8, 0);
    border: 2px solid #f0f4f7;
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;
}